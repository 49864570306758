const createPublicApi = require('./public-api')
const createUserService = require('./services/user')
const {parseConfigItems} = require('./config-items-parser')
const {initializeMonitoring, toMonitored} = require('./utils/monitoring')
const menuRenderer = require('./services/menu-renderer')
const {find} = require('./utils/find')
const {setMemoryStorage, getMemoryStorage} = require('./services/memory-storage')

let publicApi
let userService

function renderMenus({wixCodeApi, appParams, config, $w}, userCounters) {
    const init = Promise.resolve()
    const viewedUser = userService.getViewedUser()
    const currentUser = userService.getCurrentUser()
    const parsedConfigItems = parseConfigItems(config)
    const memoryStorage = getMemoryStorage()
    const isMobile = wixCodeApi.window.formFactor === 'Mobile' 

    init.then(() => {
        // In preview we need to fetch numbers late
        if (wixCodeApi.window.viewMode === 'Preview') {
            return userService.getMenuCounters(viewedUser, wixCodeApi.site.getAppToken(appParams.appDefinitionId)).catch(() => {})
        }
        return userCounters
    }).then(counters => {
        const currentUserRoles = userService.getRoles()[currentUser.id] || []
        const viewedUserRoles = userService.getRoles()[viewedUser.id] || []
        const parsedRouters = (appParams.appRouters || []).map(router => ({...router, config: JSON.parse(router.config)}))
        const parsedRoutersConfigs = parsedRouters.map(router => router.config)
        const publicRouter = parsedRouters.find(router => router.config.type === 'public')
        const publicRouterPrefix = publicRouter.prefix

        toMonitored('renderMembersMenuItems', () => menuRenderer.renderMembersMenus({$w, parsedRoutersConfigs, viewedUserRoles, viewedUser, currentUser, appsCounters: counters, parsedConfigItems, memoryStorage, publicRouterPrefix, isMobile}))()
        toMonitored('renderLoginMenuItems', () => menuRenderer.renderLoginMenus({$w, parsedRoutersConfigs, currentUserRoles, currentUser, appsCounters: counters, memoryStorage, publicRouterPrefix, isMobile}))()
    })
}

const initAppForPage = ({routerReturnedData, appRouters = []}, {storage}, wixCodeApi) => {
    userService = createUserService.init(wixCodeApi)
    publicApi = createPublicApi({appRouters, routerReturnedData, wixCodeApi})
    setMemoryStorage(storage.memory)

    if (!routerReturnedData) {
        return Promise.resolve()
    }
    const slugs = routerReturnedData.memberData && routerReturnedData.memberData.slugs || []
    const viewedUserId = routerReturnedData.memberData && routerReturnedData.memberData.memberContactId || routerReturnedData.userId
    const primarySlug = find(slugs, slug => slug.primary === true)
    const viewedUserSlug = primarySlug && primarySlug.name || viewedUserId
    const viewedUserData = {
        id: viewedUserId,
        slug: viewedUserSlug
    }

    userService.setViewedUser(viewedUserData)
    userService.setRoles(routerReturnedData.roles || {})

    return Promise.resolve()
}

const createControllers = (controllerConfigs) => {
    return controllerConfigs.map((config) => {
        const pageReady = () => {
            let viewedUser,
                currentUser,
                userRoles = {}

            const {wixCodeApi, appParams} = config

            if (wixCodeApi.window.rendering.env === "backend") {
                return
            }

            Promise.resolve()
                .then(() => userService.setCurrentUser(wixCodeApi.user.currentUser, wixCodeApi.site.getAppToken(appParams.appDefinitionId)))
                .then(() => {
                    currentUser = userService.getCurrentUser()
                    viewedUser = userService.getViewedUser()
                    userRoles = userService.getRoles()
                    const needToFetchRoles = Object.keys(userRoles).length === 0 && currentUser.loggedIn

                    return Promise.all([
                        needToFetchRoles && userService.fetchRoles(viewedUser.id, currentUser.id, wixCodeApi.site.getAppToken(appParams.appDefinitionId)),
                        userService.getMenuCounters(viewedUser.id ? viewedUser : currentUser, wixCodeApi.site.getAppToken(appParams.appDefinitionId)).catch(() => {})
                    ]).then(([roles, numbers]) => {
                        if (needToFetchRoles) {
                            userService.setRoles(roles)
                        }
                        renderMenus(config, numbers)
                    })
                })
                .then(() => {
                    wixCodeApi.user.onLogin(loggedInUser => {
                        return toMonitored('onLogin', () => Promise.resolve()
                            .then(() => userService.setCurrentUser(loggedInUser, wixCodeApi.site.getAppToken(appParams.appDefinitionId)))
                            .then(() => {
                                const needToFetchRoles = loggedInUser.loggedIn && !userRoles[loggedInUser.id]
                                const needToFetchNumbers = viewedUser && viewedUser.id
                                
                                return Promise.all([
                                    needToFetchRoles && userService.fetchRoles(viewedUser.id, loggedInUser.id, wixCodeApi.site.getAppToken(appParams.appDefinitionId)),
                                    needToFetchNumbers && userService.getMenuCounters(viewedUser, wixCodeApi.site.getAppToken(appParams.appDefinitionId)).catch(() => {})
                                ]).then(([roles, numbers]) => {
                                    if (needToFetchRoles) {
                                        userService.setRoles(roles)
                                    }

                                    renderMenus(config, needToFetchNumbers ? numbers : {})
                                })
                            }))()
                    })

                    if (wixCodeApi.window.viewMode === 'Site') {
                        const wixLocation = wixCodeApi.location
                        const query = wixLocation.query
                        const queryParams = `${Object.keys(query).length > 0 ? '?' + Object.keys(query).map(k => `${k}=${query[k]}`).join('&') : ''}`
                        const url = `/${wixLocation.prefix}/${wixLocation.path.join('/')}${queryParams}`
                        const urlWithSlug = userService.replaceUserPatternWithSlug(url, viewedUser)
                        if (url !== urlWithSlug) {
                            wixLocation.to(urlWithSlug)
                        }
                    }
                })
        }

        return {
            pageReady,
            exports: {}
        }
    })
}

module.exports = {
    initAppForPage: function (initParams, platformApis, wixCodeApi, platformServices) {
        initializeMonitoring(initParams, platformServices)
        return toMonitored('initAppForPage', () => initAppForPage(initParams, platformApis, wixCodeApi))()
    },
    createControllers: (controllerConfigs) => toMonitored('createControllers', () => createControllers(controllerConfigs))(),
    exports: {
        hasSocialPages: (onSuccess, onError) => toMonitored('publicApi.hasSocialPages', () => publicApi.hasSocialPages(onSuccess, onError))(),
        getViewedUser: (onSuccess, onError) => toMonitored('publicApi.getViewedUser', () => publicApi.getViewedUser(onSuccess, onError))(),
        navigateToSection: (sectionData, onError) => toMonitored('publicApi.navigateToSection', () => publicApi.navigateToSection(sectionData, onError))(),
        navigateToMember: (memberInfo, onError) => toMonitored('publicApi.navigateToMember', () => publicApi.navigateToMember(memberInfo, onError))(),
        getSectionUrl: (sectionData, onError) => toMonitored('publicApi.getSectionUrl', () => publicApi.getSectionUrl(sectionData, onError))(),
        getMemberPagePrefix: (data, onSuccess, onError) => toMonitored('publicApi.getMemberPagePrefix ', () => publicApi.getMemberPagePrefix(data, onSuccess, onError))()
    }
}
